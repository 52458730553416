import React from 'react'

interface IProps {
  pageContext: { to: string }
}

export default function Redirect({ pageContext: { to } }: IProps) {
  if (typeof window !== 'undefined') window.location.href = to

  return <div></div>
}
